<template>
  <div class="wrap-select" v-click-outside="onClickOutside">
    <div v-if="title" class="title">{{ title }}</div>
    <div class="select" :class="{ active: optionShow }">
      <div ref="dropdownAnchor" class="select-selected" :class="{init: this.multiSelect && this.value.length === 0}" @click="handleClick">
        {{ selectLabel }}
        <img
          :class="{ 'arrow-up': optionShow }"
          src="@/assets/icons/Drop.svg"
          alt=""
        />
      </div>
      <div 
        v-if="optionShow" 
        ref="dropdownMenu" 
        class="select-options" 
        :class="{ 'dropdown-up': isDropdownUp }"
      >
        <div v-if="multiSelect" class="filter">
          <input
            type="text"
            ref="input"
            v-model="filterText"
            :placeholder="$t('search')"
          />
          <div
            v-if="filterText.length > 0"
            class="clear"
            @click="clearFilter">
          </div>
          <div v-else class="search-icon"></div>
        </div>
        <div
          v-if="multiSelect"
          class="select-option option-all"
          @click="selectAll"
        >
          <div
            class="multi-check"
            :class="{
              checked: value.length === options.length,
              intermediate: value.length > 0 && value.length < options.length
            }"
          ></div>
          <span>{{ $t('search_all') }}</span>
        </div>
        <div class="options" :style="{ 'max-height': optionsHeight + 'px' }">
          <div
            v-for="item in filterOptions"
            :key="item.label"
            class="select-option"
            :class="{
              selected: value === item.value,
              'multi-select-option': multiSelect
            }"
            @click="optionSelect(item.value)"
          >
            <div v-if="item.locked && item.locked === 1" class="locked"></div>
            <div
              v-if="multiSelect"
              class="multi-check"
              :class="{ checked: value.includes(item.value) }"
            ></div>
            <div v-if="item.icon" class="icon">
              <img :src="getIcon(item.icon)" alt="" />
            </div>
            <span>{{ item.label }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: 'Select',
  props: ['title', 'multiSelect', 'value', 'options'],
  data() {
    return {
      optionShow: false,
      isDropdownUp: false,
      filterText: '',
      optionsHeight: 300
    }
  },
  computed: {
    selectLabel() {
      let str = ''
      if (this.multiSelect) {
        if (this.value.length === 0) {
          str = this.$t('search_not_specified')
        } else if (this.value.length === this.options.length) {
          str = this.$t('search_all')
        } else str = `${this.$t('selected')}(${this.value.length})`
      } else {
        if (Array.isArray(this.value)) {
          let option = this.options.find((item) => {
            if (item.value.length === this.value.length) {
              let isEqual = true
              item.value.forEach((p) => {
                if (!this.value.includes(p)) isEqual = false
              })
              return isEqual
            } else return false
          })
          return option?.label
        } else {
          str = this.options.find((item) => item.value === this.value)?.label
        }
      }
      return str
    },
    filterOptions() {
      return this.options.filter((item) =>
        String(item.label).toLowerCase().includes(this.filterText.toLowerCase())
      )
    }
  },
  watch: {
    optionShow(val) {
      if (val && this.multiSelect) {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted() {
    window.addEventListener('resize', this.checkDropdownUp)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDropdownUp)
  },
  methods: {
    getIcon(fn) {
      return require(`@/assets/icons/${fn}`)
    },
    handleClick() {
      this.optionShow = !this.optionShow
      this.checkDropdownUp()
    },
    checkDropdownUp() {
      this.$nextTick(() => {
        const menu = this.$refs.dropdownMenu
        const anchor = this.$refs.dropdownAnchor.getBoundingClientRect()
        
        const spaceBelow = window.innerHeight - anchor.bottom
        if (menu && spaceBelow < anchor.top) {
          this.isDropdownUp = true
          this.optionsHeight = anchor.top * 0.8 - 78
        } else {
          this.isDropdownUp = false
          this.optionsHeight = spaceBelow * 0.8 - 78
        }
      })
    },
    optionSelect(value) {
      // 多選模式下不關閉下拉選單, 單選才關閉
      let tmpVal = value
      if (this.multiSelect) {
        tmpVal = [...this.value]
        const idx = this.value.findIndex((item) => item === value)
        if (idx >= 0) tmpVal.splice(idx, 1)
        else tmpVal.push(value)
      } else {
        this.optionShow = false
      }
      this.$emit('input', tmpVal)
    },
    clearFilter() {
      this.filterText = ''
      this.$refs.input.focus()
    },
    selectAll() {
      const result =
        this.value.length === this.options.length
          ? []
          : this.options.map((item) => item.value)
      this.$emit('input', result)
    },
    onClickOutside() {
      this.optionShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  user-select: none;
}

.title {
  @include font_style(px2rem(14), px2rem(21));
  margin-bottom: 4px;
}

.select {
  width: 100%;
  position: relative;
}

.select-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: px2rem(36);
  background: #ffffff;
  border-radius: 8px;
  padding: 0 12px;
  @include font_style(px2rem(16), px2rem(24), #191919);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  position: relative;

  &.init {
    color: $color_placeholder;
  }

  img {
    width: 16px;
    height: 16px;
    @include filter_191919;
    transition: all 200ms ease-in-out;

    &.arrow-up {
      transform: rotate(180deg);
    }
  }
}

.select-options {
  position: absolute;
  width: 100%;
  color: #ffffff;
  background: #151b35;
  border: 1px solid #4a5c78;
  border-radius: 2px;
  z-index: 1;
  transition: all 375ms ease-in-out;

  &.dropdown-up {
    bottom: calc(100% + 3px);
  }
}

.filter {
  display: flex;
  align-items: center;
  padding: 6px 12px 3px 12px;
}

.filter span {
  font-weight: 300;
  color: #9d9d9d;
  margin-right: 6px;
}

.filter input {
  flex: 1;
  width: 100%;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: px2rem(18);
  font-weight: 300;
  border-radius: 8px;
  height: 36px;
  padding-left: 12px;
  padding-right: 32px;
  background: #4a5c7866;
}

.clear {
  position: absolute;
  right: 26px;
  top: 18px;
  width: 12px;
  height: 12px;
  cursor: pointer;
  background-image: url('../../../assets/icons/clear.svg');
  background-size: 12px;
}

.clear:hover {
  background-image: url('../../../assets/icons/clear-yellow.svg');
}

.search-icon {
  position: absolute;
  right: 24px;
  width: 20px;
  height: 20px;
  background-image: url('../../../assets/icons/feather-search-gray.svg');
  cursor: pointer;
}

.search-icon:hover {
  background-image: url('../../../assets/icons/feather-search-hover.svg');
}

.options {
  max-height: 500px;
  overflow: overlay;
  padding-bottom: 6px;
}

.select-option {
  position: relative;
  width: 100%;
  user-select: none;
  padding: 4px 12px;
  @include font_style(px2rem(16), px2rem(24), #ffffff);
  word-break: break-all;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.select-option:not(.selected):hover {
  background: #4a5c78;
}

.select-option span {
  flex: 1;
  text-align: center;
}

.selected {
  background: #6e7d93;
}

.multi-select-option {
  justify-content: flex-start;
}

.multi-select-option span {
  text-align: left;
}

.selected.multi-select-option {
  background: #151b35;
}

.option-all {
  border-bottom: 1px solid #4a5c78;
  justify-content: flex-start;
}

.option-all span {
  text-align: left;
}

.multi-check.intermediate {
  background: #ffc600;
  border-color: #ffc600;
  position: relative;
}

.multi-check.intermediate:after {
  content: '';
  width: 10px;
  height: 2px;
  background: #282942;
  position: absolute;
  left: 3px;
  top: 7px;
  z-index: 2;
}

.select-option .icon {
  width: 20px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
}

.locked {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 24px;
  background: #f94144;
  border-radius: 0px 4px 4px 0px;
}

.multi-check {
  position: relative;
  width: 18px;
  height: 18px;
  border: 1px solid #ffffff;
  border-radius: 3px;
  margin-right: 12px;
  top: 3px;
}

.multi-check::before {
  content: '';
  position: absolute;
  width: 7px;
  height: 12px;
  border-right: 2px solid #19223b;
  border-bottom: 2px solid #19223b;
  box-sizing: border-box;
  left: 4px;
  top: 0px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
}

.checked {
  background: #ffc600;
  border-color: #ffc600;
}

.multi-check.checked::before {
  transform: scale(1) rotate(45deg);
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 2px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background: #9d9d9d;
}

::-webkit-scrollbar-thumb:horizontal {
  border-radius: 6px;
}

::-webkit-scrollbar-corner {
  background: #151b35;
}
</style>